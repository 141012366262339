'use client';

import React from "react";
import { usePathname } from "next/navigation";

import { AnnouncementBarResponseTypes } from "@src/graphql/queries.types";
import { GQL_FETCH_ANNOUNCEMENT_BAR } from "@src/graphql/queries";

import ParagraphSeparator from "../ParagraphSeparator";
import RenderBodyCopy from "../RenderBodyCopy";

import useEbookSlideAnimate from "@src/hooks/useEbookSlideAnimate";

import fetchAPI from '@src/utils/fetchAPI'

import { AnnouncementBarPropsTypes } from "./AnnoucementBar.types";

const AnnouncementBar: React.FC = () => {
    const pathname = usePathname();

    const [data, setData] = React.useState<AnnouncementBarPropsTypes>();

    React.useEffect(() => {
        fetchAPI<AnnouncementBarResponseTypes>({
            query: GQL_FETCH_ANNOUNCEMENT_BAR,
        }).then((res) => setData(res.eBooks));
    }, []);

    const ebookSlideRef = useEbookSlideAnimate();
    const topicCollections = data?.topicCollection?.items;
    const multipleEbook = topicCollections && topicCollections?.length > 1;

    const pathNameList = ['solutions', 'pathologies', 'clinicians', 'products'];
    const productList = [
        'airbands',
        'forcedecks',
        'humantrak',
        'nordbord',
        'smartspeed',
        'valdhub',
        'dynamo',
        'forceframe',
        'msked',
        'scoreit',
        'telehab',
    ];

    const whiteBar = pathNameList.some((name) => pathname.includes(name)) &&
        !productList.some((name) => pathname.includes(name));

    return (
        <div
            ref={ebookSlideRef}
            id='announcement-bar'
            className={`fixed invisible top-[7.4rem] z-[100] flex w-full justify-center gap-[0.4rem] overflow-hidden text-center lg:!text-[1.6rem] font-[500] shadow-lg items-center ${whiteBar ? 'bg-[#EFEFEF] text-primary' : 'bg-primary text-body-copy--light'} ${multipleEbook ? 'py-[1rem]' : 'py-[0.35rem]'}`}
        >
            {data?.title && <p className={`self-center text-left ${multipleEbook ? 'ml-[-24rem]' : ''}`}>{data?.title}</p>}

            {data?.bodyCopy && <ParagraphSeparator className={` self-center text-left ${multipleEbook ? 'ml-[-24rem]' : ''}`}>
                <RenderBodyCopy
                    bodyCopy={data?.bodyCopy?.json?.content}
                    className="font-[400]"
                />
            </ParagraphSeparator>}

            <div className={`relative flex items-center`}>
                {topicCollections?.map((item) => {
                    return (
                        <a
                            key={item?.title}
                            href={item?.link}
                            target='_blank'
                            className={`text-left ${multipleEbook ? 'absolute invisible w-[30rem]' : ''} ${whiteBar ? 'text-primary hover:text-primary--v2' : 'text-body-copy--light hover:text-[#ECEAE8]'}`}
                        >
                            <RenderBodyCopy
                                bodyCopy={item?.bodyCopy?.json?.content}
                                className="font-[400] lg:!text-[1.6rem] [&>strong]:!font-[700]"
                            />
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default AnnouncementBar;
