'use client';
import React from 'react';

import ArrowPlain from '@src/components/Icons/ArrowPlain';

import useGsapLoaded from '@src/hooks/useGsapLoaded';

import scrollTo from '@src/utils/scrollTo';

const ScrollToTop: React.FC = ({ }) => {
  const ref = React.useRef(null);
  const isGsapLoaded = useGsapLoaded(); // Use the hook to check if GSAP is loaded

  React.useEffect(() => {
    if (!isGsapLoaded) return;

    const element = ref?.current;
    if (isGsapLoaded) {
      window?.gsap?.fromTo(
        element,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          scrollTrigger: {
            trigger: 'body',
            start: 'bottom bottom+=30%',
            end: 'bottom bottom',
            scrub: true,
          },
        }
      );
    }
  }, [isGsapLoaded]);

  return (
    <button
      ref={ref}
      onClick={scrollTo({ target: 'body' })}
      className='fixed bottom-[2.4rem] right-[2.4rem] z-[100000] flex h-[3.2rem]  w-[3.2rem] -rotate-90 cursor-pointer items-center justify-center rounded-full bg-[#D9D9D9] opacity-[0] shadow-lg'
      aria-label='Scroll to top'
    >
      <ArrowPlain className='fill-[#54585A]' />
    </button>
  );
};

export default ScrollToTop;
