export const GQL_FETCH_PRODUCTS_PAGE_HERO_ENTRY = `
  hero: pageComponentsCollection(where: { sys: { id: "39a5PqXzZ85OnzN3p36xtB" } }, limit: 1) {
    items {
        ... on Banner {
        scrollTo
        title
        heroImage {
          url
          description
          width
          height
        }
        heroImageSecondary {
          url
          description
          width
          height
        }
        heroImageTertiary {
          url
          description
          width
          height
        }
      }
    }
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_EFFECTIVE_CARE_ENTRY = `
   effectiveCare: pageComponentsCollection(where: {sys: {id: "1PLa2ydzXyJClLGKqDznwY"}}, limit: 1) {
      items {
        ... on SectionHeader {
          scrollTo
          title
          bodyCopy {
            json
          }
        }
      }
    }
`;

export const GQL_FETCH_PRODUCTS_PAGE_MORE_TIME_FOR_PATIENTS_ENTRY = `
   moreTimeForPatients: pageComponentsCollection(where: {sys: {id: "18Q5fPSaw4gZTvs6YoCMCI"}}, limit: 1) {
      items {
        ... on SectionHeader {
          scrollTo
          title
          bodyCopy {
            json
          }
        }
      }
    }
`;

export const GQL_FETCH_PRODUCTS_PAGE_OBJECTIVE_MEASUREMENT_ENTRY = `
  objectiveMeasurement: pageComponentsCollection(where: { sys: { id: "7EbAmIt3XPryHLfHTLTbaz" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_OBJECTIVE_MEASUREMENT_SOLUTIONS_ENTRY = `
  objectiveSolutions: pageComponentsCollection(where: { sys: { id: "FhPQXAYvDxPrMDTXLe3B2" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
      }
    }   
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_RELATED_PRODUCTS_ENTRY = `
  products: productCollection(order: order_ASC, where: {contentfulMetadata: {tags: {id_contains_some: "vh"}}, categories: {sys: {id: "1bT1ZLv57CFDzSonITjpTf"}}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_BFR_CUFFS_ENTRY = `
  bfrCuffs: pageComponentsCollection(where: { sys: { id: "66nX3cXiciMRgCE9GT5UjR" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_NORMATIVE_DATA_ENTRY = `
  normativeData: pageComponentsCollection(where: { sys: { id: "6nrjnw8KaHSTXfWJPXMsY" } }, limit: 1) {
    items {
			... on SectionHeader {
        preTitle
        title
        scrollTo
        bodyCopy {
          json
        }
      }
    }   
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_DATA_POINTS_ENTRY = `
  dataPoints: pageComponentsCollection(where: { sys: { id: "3Ycr33pLaqceDhZiGWOYfZ" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_BETTER_COMMUNICATION_ENTRY = `
  betterCommunication: pageComponentsCollection(where: { sys: { id: "1HWiwNTGYbR4NfAxJjcbtm" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_TOOLKIT_ENTRY = `
  toolkit: pageComponentsCollection(where: { sys: { id: "7Fxyi5S3yC7O21JLbmicDd" } }, limit: 1) {
    items {
			... on SectionHeader {
        scrollTo
        title
        bodyCopy {
          json
        }
      }
    }   
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_RELATED_PRODUCTS_2ND_ENTRY = `
  products2nd: productCollection(order: order_ASC, where: {contentfulMetadata: {tags: {id_contains_some: "vh"}}, categories: {sys: {id: "5YN6rPmGt1T4U21M3nohCG"}}}) {
    items {
      sys {
        id
      }
      mediaPrimary {
        description
        url
        width
        height
      }
      logo {
        url
        description
      }
      name
      title
      slug
      bodyCopy {
        json
      }
      button {
        text
        url
        type
        target
      }
    }
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_REPORTING_ENTRY = `
  reporting: pageComponentsCollection(where: { sys: { id: "MTmysQUyDk5BljqKsbVj0" } }, limit: 1) {
    items {
			... on DuplexComponent {
        scrollTo
        preTitle
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
      }
    }   
  }
`;
export const GQL_FETCH_PRODUCTS_PAGE_WE_EXIST_ENTRY = `
  weExist: pageComponentsCollection(where: { sys: { id: "4AdfvPN56yck6kyzcBYQry" } }, limit: 1) {
    items {
			... on DuplexComponent {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        mediaPrimary {
          width
          height
          url
          description
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_CLIENT_SUCCESS = `
  clientSuccess: pageComponentsCollection(where: {sys: {id: "3NAtn1mGDRkTvSWjMeIC6b"}}, limit: 1) {
    items {
      ... on Grid {
        bodyCopy {
          json
        }
        scrollTo
        topicCollection {
          items {
            ... on Card {
              sys {
                id
              }
              title
              bodyCopy {
                json
              }
              mediaPrimary {
                url
                description
                width
                height
              }
            }
          }
        }
      } 
    }
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_CTA_ENTRY = `
  callToAction: pageComponentsCollection(where: { sys: { id: "6K3BvfeDPbtaHAHB6jSJIo" } }, limit: 1) {
    items {
			... on SectionHeader {
        sys {
          id
        }
        scrollTo
        title
        bodyCopy {
          json
        }
        buttonCollection(limit: 3) {
          items {
            url
            text
            type
            target
          }
        }
      }
    }   
  }
`;
