import React from 'react';

import NextImage from '../NextImage';

import isEmpty from '@src/utils/isEmpty';

import { RenderBodyCopyPropTypes } from './RenderBodyCopy.types';

const RenderBodyCopy: React.FC<RenderBodyCopyPropTypes> = ({ bodyCopy, entries = [], className = '' }) => {
  if (isEmpty({ value: bodyCopy })) return null;

  const findEntryById = (id: string) => entries?.find((entry) => entry?.sys?.id === id);

  // Function to render text with marks
  const renderMarkedText = (node: any, key: any) => {
    let textElement = <p className='inline [&::after]:inline [&::before]:inline' key={key}>
      {node?.value}
    </p>;

    node.marks.forEach((mark: any) => {
      switch (mark.type) {
        case 'bold':
          textElement = (
            <strong className='inline font-[500]' key={key}>
              {textElement}
            </strong>
          );
          break;
        case 'italic':
          textElement = <em key={key} className='inline'>
            {textElement}
          </em>;
          break;
        case 'underline':
          textElement = <u key={key} className='inline'>
            {textElement}
          </u>;
          break;
        default:
          break;
      }
    });

    return textElement;
  };

  return bodyCopy.map((node: any, index: any) => {
    switch (node.nodeType) {
      case 'text':
        return renderMarkedText(node, index);
      case 'paragraph':
        return (
          <div className={`text-[1.6rem] lg:text-[1.8rem] [&>*]:!inline ${className}`} key={`paragraph-${index}`}>
            {node.content.map((contentNode: any, contentIndex: any) => (
              <RenderBodyCopy bodyCopy={[contentNode]} key={`content-${contentIndex}`} />
            ))}
          </div>
        );
      case 'hyperlink':
        return (
          <a
            key={`hyperlink-${index}`}
            href={node.data.uri}
            target='_blank'
            rel='noopener noreferrer'
            className='text-[1.6rem] font-[400] underline'
          >
            {node.content.map((contentNode: any, contentIndex: any) => (
              <RenderBodyCopy bodyCopy={[contentNode]} key={`link-content-${contentIndex}`} />
            ))}
          </a>
        );
      case 'unordered-list':
        return (
          <ul className='my-[2rem] ml-[2.4rem] list-disc text-[1.6rem] font-[400] [&>li>p]:before:mb-[0] [&>li>p]:before:mt-[0]'>
            {node.content.map((contentNode: any, contentIndex: any) => (
              <li
                key={`list-content-${contentIndex}`}
                className={`mb-[0.8rem] font-[200] ${className}`}
              >
                {contentNode.content.map((listNode: any, listIndex: any) => (
                  <RenderBodyCopy bodyCopy={[listNode]} key={`list-content-${listIndex}`} />
                ))}
              </li>
            ))}
          </ul>
        );
      case 'heading-2':
        return (
          <h2 key={`heading-2-content-${index}`} className="text-[2.2rem] font-[400] leading-[1.3] xl:text-[2.8rem] mb-[2.4rem] mt-[2.4rem] md:mt-[4.4rem]">{node?.content?.[0]?.value}</h2>
        );
      case 'embedded-entry-block': {
        const entry = findEntryById(node?.data?.target?.sys?.id);

        if (!isEmpty({ value: entry })) {
          if (entry?.media && entry?.media?.url) {
            const imageElement = (
              <NextImage
                className='!mb-[0] h-auto w-full' // Adjust according to your styling needs
                key={`image-${entry?.sys?.id}`}
                src={entry?.media?.url}
                alt={entry?.media?.description || 'Embedded Image'}
                aspectRatio={entry?.media?.width / entry?.media?.height}
                width={entry?.media?.width}
              />
            );

            if (!isEmpty({ value: entry?.url })) {
              // If URL is present, wrap the image in an anchor
              return (
                <a
                  key={`entry-link-${index}`}
                  href={entry?.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {imageElement}
                </a>
              );
            }

            return imageElement;
          } else if (entry?.bodyCopy) {
            // Assuming entry has a bodyCopy property to be rendered
            return (
              <RenderBodyCopy
                bodyCopy={entry?.bodyCopy?.json?.content}
                entries={entry?.bodyCopy?.links?.entries?.block}
                key={`entry-${index}`}
              />
            );
          }
        }
        return null;
      }
      default:
        return null;
    }
  });
};

export default RenderBodyCopy;