import { BLOCKS } from './types';

export type SysTypes = {
  id: string;
};

export type ButtonTypes = {
  sys: SysTypes;
  url: string;
  text: string;
  type: string;
  target: string;
};

/* eslint-disable no-unused-vars */
export enum GQL_PAGE_ID_TYPES {
  HOME_LANDING_PAGE = '3oI66x0FLi0pgAB08H6Xcw',
  SOLUTIONS_LANDING_PAGE = '5wkdVJLZdz4djSzVdgZY4k',
  CLINICIANS_LANDING_PAGE = '6CkZxgAv4c5ziWcXveqepb',
  PATHOLOGIES_LANDING_PAGE = '7JrB8B4yqDxLb7fFkGcELt',
  PRODUCTS_LANDING_PAGE = '166QgRPjxBUV6vPtWoqcth',
  NEWS_AND_RESEARCH_LANDING_PAGE = '4YuVlBij70lkl4IduGW6Ip',
  CONTACT_LANDING_PAGE = '7LUNPdGVU0Znzs96UeKSig',
  PRIVATE_POLICY_LANDING_PAGE = 'jV92zFcwSLMxTTmeJBHJ5',

  // PRODUCT LANDING PAGES
  AIRBANDS_LANDING_PAGE = '7jD8FFY1FX2ZC4e21vrzK9',
  DYNAMO_LANDING_PAGE = '5hRVNrgLowsa9faboZ8Y3U',
  FORCEFRAME_LANDING_PAGE = '1VWIckmMFSicLSaQI1j5EA',
  FORCEDECKS_LANDING_PAGE = '5hWleLfjGGc0j5HakYLe3p',
  HUMANTRAK_LANDING_PAGE = '6yf75lws88nihmGUELEcqH',
  MSKED_LANDING_PAGE = 'RIqE2YbGMrK3Rk9DsOFVk',
  NORDBORD_LANDING_PAGE = '6qeBRXRi6D8yevxjZsEEoi',
  SCOREIT_LANDING_PAGE = '4Nt1yhOSjZFqQzWhY7ZqPf',
  SMARTSPEED_LANDING_PAGE = '53i7HkZfaBUE1Rf9rFUQXb',
  TELEHAB_LANDING_PAGE = '6RD2kT435KBrvyVlDvVn7Y',
  VALDHUB_LANDING_PAGE = '1gYX4jaSC5XAcLnbk0VLah',
}
/* eslint-enable no-unused-vars */

export type MediaDataTypes = {
  url: string;
  description: string | null;
  height: number;
  width: number;
};

export type BlogSummaryTypes = {
  translationsCollection: any;
  languageCode: any;
  sys: SysTypes;
  total: string;
  author: string | null;
  title: string | null;
  slug: string | null;
  datePublished: string;
  thumbnail: MediaDataTypes;
  excerpt: string | null;
  categoriesCollection: CategoriesCollectionTypes | null;
};

export type ForceDecksCarouselBlogTypes = {
  slug: string | null;
  thumbnail: MediaDataTypes;
};

export type categoriesTypes = {
  title: string;
  slug: string;
  sys: SysTypes;
};

export type CategoryTypes =
  | 'All'
  | 'Case Studies'
  | 'News & Announcements'
  | 'Guest Spot'
  | 'Guides'
  | 'Whitepapers'
  | 'Clinical Studies';

// export type ProductTypes =
//   | 'AirBands'
//   | 'ForceDecks'
//   | 'ForceFrame'
//   | 'SmartSpeed'
//   | 'NordBord'
//   | 'VALD Hub'
//   | 'MSKed'
//   | 'ScoreIt'
//   | 'TeleHab'
//   | 'all';

export type CategoriesCollectionTypes = {
  items: categoriesTypes[];
};

export type ContentTypes = {
  data: object;
  marks: any[];
  value: string;
  nodeType: string;
};

export type BodyCopyJsonTypes = {
  data: object;
  content: {
    data: object;
    content: ContentTypes[];
    nodeType: string;
  }[];
  nodeType: string;
};

export type LinksBlockTypes = {
  sys: SysTypes;
  __typename: string;
  text: string;
  type: string;
  target: string;
};
export type LinksInlineTypes = {
  sys: SysTypes;
  title: string;
  media: {
    url: string;
    height: number;
    width: number;
    description: string | null;
  };
};
export type LinksAssetTypes = {
  sys: SysTypes;
  url: string;
  title: string;
  width: number | null;
  height: number | null;
  contentType: string;
  description: string | null;
};

export type BodyCopyLinksTypes = any;

export type BodyCopyTypes = {
  link: string;
  json: BodyCopyJsonTypes;
  links: BodyCopyLinksTypes;
};

export interface ButtonTopicItemTypes {
  sys: SysTypes;
  url: string;
  text: string;
  type: string;
  target: string;
}

export interface GridTopicItemTypes {
  sys: SysTypes;
  preTitle: string;
  title: string;
  mediaPrimary: MediaDataTypes;
  bodyCopy: BodyCopyTypes;
}

// =============================================
//          META DATA QUERY RESPONSE TYPES
// =============================================
export type LandingPageMetaDataQueryResponseTypes = {
  page: {
    seoMetadata: {
      canonical: unknown;
      title: string;
      description: string;
      previewImage: {
        url: string;
      };
      index: string;
      follow: string;
    };
  };
};

// =============================================
//          PAGE QUERY RESPONSE TYPES
// =============================================

// =========================================================================================
// =============================================
//               GENERIC
// =============================================

// =============================================
//                  Banner Types
// =============================================
export type Bannertypes = {
  text: string;
  url: string;
  type: boolean;
};

export type BannerAssetTypes = {
  sys: SysTypes;
  description: string;
  size: number;
  url: string;
  width: number;
  height: number;
};

export type BannerBodyTextContentTypes = {
  data: object;
  marks: [];
  value: string;
  nodeType: string;
  content: BannerBodyTextContentItemTypes[];
};

export type BannerBodyTextContentItemTypes = {
  data: object;
  marks: [];
  value: string;
  nodeType: string;
};

export type BannerBodyTextLinksAssetsTypes = {
  block: any[]; // Adjust the type according to the actual structure
};

export type BannerBodyTextLinksTypes = {
  assets: BannerBodyTextLinksAssetsTypes;
};

export type BannerBodyTextTypes = {
  json: JsonTypes;
  links: BannerBodyTextLinksTypes;
};

export type BannerTypes = {
  heading: string;
  buttonsCollection: ButtonsCollectionTypes;
  imageCollection: ImageCollectionTypes;
  bodyText: BannerBodyTextTypes;
};

export type JsonTypes = {
  data: object;
  content: BannerBodyTextContentTypes[];
  nodeType: string;
};

export type ButtonsCollectionTypes = {
  items: Bannertypes[];
};

export type ImageCollectionTypes = {
  items: BannerAssetTypes[];
};

// =============================================
//              Carousel Types
// =============================================
export type CarouselTypes = {
  sys: SysTypes;
  headline: string;
  cardLayout: string;
  tagline: string;
  body: any;
  json: JsonTypes;
  block: CarouselAssetTypes;
};
export type CarouselAssetTypes = {
  title: string;
  description: string;
  contentType: string;
  fileName: string;
  size: number;
  url: string;
  width: number;
  height: number;
};

// =============================================
//              Product Types
// =============================================
export type ProductTypes = {
  sys: SysTypes;
  productName: string;
  url: string;
  subtitle: string;
  imagesCollection: ProductsImageCollectionTypes;
  categoriesCollection: categoriesCollectionTypes;
  excerpt: string;
  description: string;
  category: string;
  order: number;
};

export type ProductsImageCollectionItemTypes = {
  fileName: string;
  height: number;
  url: string;
  width: number;
  description: string;
};

export type ProductsImageCollectionTypes = {
  items: ProductsImageCollectionItemTypes[];
};

export type categoriesCollectionTypes = {
  items: categoriesCollectionItemTypes[];
};

export type categoriesCollectionItemTypes = {
  title: string;
  slug: string;
};

// =============================================
//                  Blog types
// =============================================
export type HyperlinkNodeTypes = {
  nodeType: 'hyperlink';
  data: {
    uri: string;
  };
  content: Node[];
};

export type EmbeddedAssetBlockNodeTypes = {
  nodeType: 'embedded-asset-block';
  data: {
    target: {
      sys: {
        id: string;
        type: string;
        linkType: string;
      };
    };
  };
  content: any[]; // Update with the correct type if applicable
};

export type EmbeddedEntryBlockNodeTypes = {
  nodeType: 'embedded-entry-block';
  data: {
    target: {
      sys: {
        id: string;
        type: string;
      };
    };
  };
  content: any[]; // Update with the correct type if applicable
};

export type ParagraphContentTypes = { data: object; marks: []; nodeType: string; value: string };

export type ParagraphNodeTypes = {
  nodeType: BLOCKS.PARAGRAPH;
  data: {
    target: {
      sys: {
        id: string;
        type: string;
      };
    };
  };
  content: ParagraphContentTypes[];
};

export type Node =
  | HyperlinkNodeTypes
  | EmbeddedAssetBlockNodeTypes
  | EmbeddedEntryBlockNodeTypes
  | ParagraphNodeTypes;

export type JsonDataTypes = {
  nodeType: string;
  data: object;
  content: Node[];
};

export type BlogPostImage = {
  url: string;
  description: string | null;
  height: number;
  width: number;
};

export type PostBodyLinksTypes = {
  assets: PostBodyAssetsTypes;
  entries: PostBodyEntriesTypes;
};

export type AssetBlockTypes = {
  url: string;
  sys: {
    id: string;
  };
};

export type PostBodyAssetsTypes = {
  block: AssetBlockTypes[];
};

export type EntryBlockTypes = {
  sys: {
    id: string;
  };
};

export type PostBodyEntriesTypes = {
  block: any;
  inline: EntryBlockTypes[];
};

export type BlogPostBodyTypes = {
  json: JsonDataTypes;
  links: PostBodyLinksTypes;
};

export type BlogDetailsTypes = {
  author: string | null;
  title: string | null;
  slug: string | null;
  datePublished: string | null;
  body: BlogPostBodyTypes;
  featuredImage: BlogPostImage;
  thumbnail: BlogPostImage;
  categoriesCollection: CategoriesCollectionTypes | null;
  excerpt: string | null;
};

