'use client';

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Modal from '../Modal';
import NextImage from '../NextImage';

import setCookie from '@src/utils/setCookie';
import getCookie from '@src/utils/getCookie';
import isBot from '@src/utils/isBot';

import { CountriesInfoPropTypes } from './GeoPopup.types';

const GeoPopup = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const userTimeZone = dayjs.tz.guess();
  const userCountyTz = userTimeZone.split('/')[1];

  // Load the timezone data
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const timezoneData = require('./timezone-data.json');
  const { countries, zones } = timezoneData;

  // Define the type for timeZoneToCountry
  const timeZoneToCountry: { [key: string]: string } = {};

  Object.keys(zones).forEach((z) => {
    const cityArr = z.split('/');
    const city = cityArr[cityArr.length - 1];
    timeZoneToCountry[city] = countries[zones[z].countries[0]].abbr;
  });

  const countryCode = timeZoneToCountry[userCountyTz] ? timeZoneToCountry[userCountyTz] : '';

  const countriesInfo: CountriesInfoPropTypes = {
    DE: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/1Aog3BbygVHM0BBnPwr9v2/117accf1cdd9c877467e0e6fda53b499/btm_popup_germany_v2.png',
      introbold: 'Nimm Kontakt',
      intro: ' zu einem unserer deutschen Mitarbeiter auf',
      bdmname: 'Vincent Simons, John Boyer, Andrew Quarmby',
      positionatvald: 'Business Development Manager',
      emailaddress: ' v.simons@vald.com, j.boyer@vald.com, a.quarmby@vald.com',
      emailaddressRef:
        'mailto:v.simons@vald.com, j.boyer@vald.com, a.quarmby@vald.com?subject=Online enquiry from VALD Performance&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:v.simons@vald.com, j.boyer@vald.com, a.quarmby@vald.com?subject=Online enquiry from VALD Performance&cc=info@vald.com',
      contactbutton: 'Jetzt kontaktieren',
    },
    JP: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/48fXdg9pfOxrNNzIkCS2Hr/4c8e930c6a8ea8de06f7348e4a43e710/VP_Website-Popup_Japan.png',
      introbold: '',
      intro: ' 日本語でのお問い合わせはこちらまで',
      bdmname: 'Motoki Miyamoto',
      positionatvald: 'Business Development Manager',
      emailaddress: ' m.miyamoto@vald.com',
      emailaddressRef:
        'mailto:m.miyamoto@vald.com?subject=Online enquiry from VALD Performance&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:m.miyamoto@vald.com?subject=Online enquiry from VALD Performance&cc=info@vald.com',
      contactbutton: 'お問い合わせ',
    },

    IT: {
      bdmname: 'Davide Zottola, Marco Cuniberti',
      emailaddress: ' d.zottola@vald.com, m.cuniberti@vald.com',
      emailaddressRef:
        'mailto:d.zottola@vald.com, m.cuniberti@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:d.zottola@vald.com, m.cuniberti@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/5R5kXQIOzuBnHeK7MS0G7i/b215bf7653998ed800f092181d7376a3/btm_popup_italy_v2.png',
      introbold: 'Mettiti in contatto',
      intro: ' con uno dei nostri membri in Italia',
      positionatvald: 'Business Development Manager',
      contactbutton: 'Contatto',
    },

    FR: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/2KEyUp7ZYX13owJyIrPdOi/99cf98b215ac48cc6688d32d2ab95355/VP_Website-Popup_France.png',
      introbold: 'Entrez en contact',
      intro: ' avec l`un des membres de notre équipe en France',
      bdmname: 'Laurent Antkowiak, Rob Parkinson',
      positionatvald: 'Business Development Manager',
      emailaddress: ' l.antkowiak@vald.com, r.parkinson@vald.com',
      emailaddressRef:
        'mailto:l.antkowiak@vald.com, r.parkinson@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com,d.cartwright@vald.com;',
      buttonlinkaddress:
        'mailto:l.antkowiak@vald.com, r.parkinson@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Contact',
    },

    MX: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/hIL5ZlGQ4uaq8f1oFOHX7/5ad9c9697ef7a60fb371b9e65d8c5a8c/VP_Website-Popup_Mexico.png',
      introbold: 'Contacta',
      intro: ' a uno de nuestros asesores en Mexico.',
      bdmname: 'Renato Trevino, Martin Lara',
      positionatvald: 'Business Development Manager',
      emailaddress: ' r.trevino@vald.com, m.lara@vald.com',
      emailaddressRef:
        'mailto:r.trevino@vald.com, m.lara@vald.com?subject=Online enquiry from VALD Health Mexico&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:r.trevino@vald.com, m.lara@vald.com?subject=Online enquiry from VALD Health Mexico&cc=info@vald.com',
      contactbutton: 'Contacta',
    },

    AR: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/5VX4oZygMBXv1eWE910aY1/6b4ecfed771b45b3a7bd689d96529d05/VP_Website-Popup_Argentina.png',
      introbold: 'Contacta',
      intro: ' a uno de nuestros asesores en Argentina.',
      bdmname: 'Enzo Cacciuttolo',
      positionatvald: 'Business Development Manager',
      emailaddress: ' e.cacciuttolo@vald.com',
      emailaddressRef:
        'mailto:e.cacciuttolo@vald.com?subject=Online enquiry from VALD Health Argentina&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:e.cacciuttolo@vald.com?subject=Online enquiry from VALD Health Argentina&cc=info@vald.com',
      contactbutton: 'Contacta',
    },

    CL: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/6R5mK9xMsatWYAnRIkilba/a7a161d1c2231337add81a6b4aeebcd2/VP_Website-Popup_Chile.png',
      introbold: 'Contacta',
      intro: ' a uno de nuestros asesores en Chile.',
      bdmname: 'Enzo Cacciuttolo',
      positionatvald: 'Business Development Manager',
      emailaddress: ' e.cacciuttolo@vald.com',
      emailaddressRef:
        'mailto:e.cacciuttolo@vald.com?subject=Online enquiry from VALD Health Chile&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:e.cacciuttolo@vald.com?subject=Online enquiry from VALD Health Chile&cc=info@vald.com',
      contactbutton: 'Contacta',
    },

    ES: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/3vGhVJsRCfwVkqdNBrRm67/4b281504b9304ba117a1bbdb710d3487/VP_Website-Popup_Spanish-3.png',
      introbold: 'Contacta un',
      intro: ' miembro de nuestro equipo en España',
      bdmname: 'Pedro Castellon, Diego Soliño',
      positionatvald: 'Business Development Manager',
      emailaddress: ' p.castellon@vald.com, d.solino@vald.com',
      emailaddressRef:
        'mailto:p.castellon@vald.com,d.solino@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:p.castellon@vald.com,d.solino@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Contacta',
    },

    CA: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/7guXW0gvVUvoMJc2Bi843D/092ae52d628c8533e009d3787080741d/VP_Website-Popup_Isolated_Canada.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in Canada',
      bdmname: 'Jason McCullagh',
      positionatvald: 'Business Development Manager',
      emailaddress: ' j.mccullagh@vald.com',
      emailaddressRef:
        'mailto:j.mccullagh@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:j.mccullagh@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Contact',
    },

    NO: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/1FnxLmWctXYMem3L3S8OY6/3a089bcf75b9c5f143b5d605031210c8/VP_Website-Popup_Isolated_Norway.png',
      introbold: 'Kom i kontakt',
      intro: ' med et av våre teammedlemmer i Norge',
      bdmname: 'Simen Thøgersen',
      positionatvald: 'Business Development Manager',
      emailaddress: ' s.thogersen@vald.com',
      emailaddressRef:
        'mailto:s.thogersen@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:s.thogersen@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Kontakt',
    },

    SI: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/25SwI0cJ8GAXPF6mxVcZW9/53dda14e6ecc2b8afc85d6869fe4bfbd/VP_Website-Popup_Isolated_Slovenia.png',
      introbold: 'Pošljite sporočilo',
      intro: ' članu naše ekipe v Sloveniji',
      bdmname: 'Sebastjan Potokar',
      positionatvald: 'Business Development Manager',
      emailaddress: ' s.potokar@vald.com',
      emailaddressRef:
        'mailto:s.potokar@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:s.potokar@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Pošlji sporočilo',
    },

    PT: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/19VG7pikOmjGFMTYmnuUb5/a55912151220b71417031d2fa1e94c75/VP_Website-Popup_Isolated_Portugal.png',
      introbold: 'Entre em contacto',
      intro: ' com um dos membros da nossa equipa em Portugal',
      bdmname: 'Diego Soliño',
      positionatvald: 'Business Development Manager',
      emailaddress: ' d.solino@vald.com',
      emailaddressRef:
        'mailto:d.solino@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:d.solino@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Contacto',
    },

    EG: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/1c8jWzMkmviNop70ClAZxZ/157bdfbd5b9d49b3deb7ef4fd0219673/VP_Website-Popup_Isolated_Egypt.png',
      introbold: 'تواصل',
      intro: ' مع أحد أعضاء فريقنا في مصر',
      bdmname: 'Antonios Sayed',
      positionatvald: 'Business Development Manager',
      emailaddress: ' a.sayed@vald.com',
      emailaddressRef:
        'mailto:a.sayed@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:a.sayed@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'اتصل',
    },

    NZ: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/67Fy3TPVHclMFpl3C2kRlp/d97be757828b9c92506da6ab522a196a/VP_Website-Popup_Isolated-NZ.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in New Zealand',
      bdmname: 'Kim Wilson',
      positionatvald: 'Business Development Manager',
      emailaddress: ' k.wilson@vald.com',
      emailaddressRef:
        'mailto:k.wilson@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:k.wilson@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Contact',
    },

    IE: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/3aAxLpCe4wXe0Pmijs7KX9/c96ff6bbc3aae0a6054b5d8b46394389/VP_Website-Popup_Isolated_Ireland.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in Ireland',
      bdmname: "Conor Tierney",
      positionatvald: 'Business Development Manager',
      emailaddress: ' c.tierney@vald.com',
      emailaddressRef:
        'mailto:c.tierney@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:c.tierney@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Contact',
    },
    PL: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/1X3ukVuYvvRC2FYAGBSRon/66b59e0738174fa9305211472db1ad43/VP_Website-Popup_Isolated_Polish.png',
      introbold: 'Skontaktuj się z',
      intro: ' jednym z naszych członków zespołu w Polsce',
      bdmname: 'Grzegorz Wojdala',
      positionatvald: 'Business Development Manager',
      emailaddress: ' g.wojdala@vald.com',
      emailaddressRef:
        'mailto:s.thogersen@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:s.thogersen@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Kontakt',
    },
    DK: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/3730WuIo7iZUGrUDHwK10T/63c18454786ed26d024f875fff1ceb02/VP_Website-Popup_Isolated_Danish.png',
      introbold: 'Kom i kontakt',
      intro: ' med et af vores teammedlemmer i Danmark',
      bdmname: 'Simen Thøgersen',
      positionatvald: 'Business Development Manager',
      emailaddress: ' s.thogersen@vald.com',
      emailaddressRef:
        'mailto:s.thogersen@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:s.thogersen@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Kontakt',
    },
    SA: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/4SJlp7LJ9ACzmyMF67T0DH/9aa8fdd3ce6c07b84124f6f4d471f965/btm_popup_saudi_v1.png',
      introbold: '',
      intro: ' تواصل مع أحد أعضاء فريقنا في المملكة العربية السعودية',
      bdmname: 'Antonios Sayed',
      positionatvald: 'Business Development Manager',
      emailaddress: ' a.sayed@vald.com',
      emailaddressRef:
        'mailto:a.sayed@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:a.sayed@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'راجع',
    },
    SE: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/6eG0cJBV7jxnPW7Vbyw6PG/9787307f88600aaf41ca1d7625c7a49b/Popup_sweden.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in Sweden',
      bdmname: 'Oda Lindahl',
      positionatvald: 'Business Development Manager',
      emailaddress: ' o.lindahl@vald.com',
      emailaddressRef:
        'mailto:o.lindahl@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:o.lindahl@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Contact',
    },
    RO: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/4anshToIgqMyaElQyVG7aF/2cf3501ce6210db758cefd0b49a588a6/Website-Popup_Isolated_Romania.png',
      introbold: 'Contactaţi-ne',
      intro: ' cu unul dintre membrii echipei noastre din România',
      bdmname: 'Brandon Garcia',
      positionatvald: 'Business Development Manager',
      emailaddress: ' b.garcia@vald.com',
      emailaddressRef:
        'mailto:b.garcia@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:b.garcia@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'a lua legatura',
    },
    TR: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/7yx7QJVDODWReoEXvNT6di/044a6d712d49292654da26e73036d2f2/Website-Popup_Isolated_Trukiye.png',
      introbold: 'Bize Ulaşın',
      intro: ` Türkiye'deki ekip üyelerimizden biriyle`,
      bdmname: 'Mert Sahan, Denizhan Isiksal',
      positionatvald: 'Business Development Manager',
      emailaddress: ' m.sahan@vald.com, d.isiksal@vald.com',
      emailaddressRef:
        'mailto:m.sahan@vald.com, d.isiksal@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:m.sahan@vald.com, d.isiksal@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Temas etmek',
    },
    RS: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/4CkmUUUC6e7jcuTaJT2Wah/4c0dcf01dadcfdf52dec5123ea10ea99/Website-Popup_Isolated_Serbia.png',
      introbold: 'Контактирајте нас',
      intro: ` са једним од наших чланова тима у Србији`,
      bdmname: 'Sebastjan Potokar',
      positionatvald: 'Business Development Manager',
      emailaddress: ' s.potokar@vald.com',
      emailaddressRef:
        'mailto:s.potokar@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:s.potokar@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Контакт',
    },
    HR: {
      imagecountry:
        'https://images.ctfassets.net/98s79sqwuajy/7usoN9nSR9HMylCKmhk7cY/2744b76dd474655bb0e14d7f208950e9/Website-Popup_Isolated_Croatia.png',
      introbold: 'Kontaktirajte nas',
      intro: ` s jednim od članova našeg tima u Hrvatskoj`,
      bdmname: 'Sebastjan Potokar',
      positionatvald: 'Business Development Manager',
      emailaddress: ' s.potokar@vald.com',
      emailaddressRef:
        'mailto:s.potokar@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      buttonlinkaddress:
        'mailto:s.potokar@vald.com?subject=Online enquiry from VALD Health&cc=info@vald.com',
      contactbutton: 'Kontakt',
    },
  };
  const stringifiedCountryCode = String(countryCode);
  const popupCountry = countryCode ? countriesInfo[stringifiedCountryCode] : null;

  const [showModal, setShowModal] = useState(false);

  const modalInfo = popupCountry;

  const handleClose = () => {
    setShowModal(false);
    setCookie('geoPopupVisited', 'true', 7);
  };

  const addedGeoPopupCookie = getCookie('geoPopupVisited');

  useEffect(() => {
    setTimeout(() => {
      if (
        modalInfo !== undefined &&
        modalInfo !== null &&
        !addedGeoPopupCookie &&
        isBot(window.navigator.userAgent) === false
      ) {
        setShowModal(true);
      }
    }, 2000);
  }, [modalInfo, addedGeoPopupCookie]);

  const breakLineBdm = modalInfo?.bdmname
    ? modalInfo.bdmname.split(', ').map((name, index) => <div key={index}>{name}</div>)
    : null;

  // console.log(bdmNames)

  return (
    <div>
      {showModal && (
        <Modal onClose={() => handleClose()}>
          <div>
            <div>
              <div className='mx-auto w-[16rem]'>
                <NextImage
                  className='order-0 mx-auto mb-[2.4rem] w-full max-w-[90%] md:mb-[0rem] md:mt-[2.4rem] lg:max-w-[70%]'
                  src={`${modalInfo?.imagecountry}`}
                  alt='A portrait'
                />
              </div>
              <div className='mt-[2.5rem] w-full  text-center text-[1.6rem]'>
                <p className='mb-[2rem]'>
                  <span className='font-bold' id='introbold'>
                    {modalInfo?.introbold}
                  </span>
                  <span id='intro'>{modalInfo?.intro}</span>
                </p>
                <p className='mb-[1.5rem]' id='bdmname'>
                  {breakLineBdm}
                </p>
                <p className='mb-[1rem]' id='positionatvald'>
                  {modalInfo?.positionatvald}
                </p>
                <p>
                  Email:
                  <a id='emailaddress' href={modalInfo?.buttonlinkaddress}>
                    {modalInfo?.emailaddress}
                  </a>
                </p>
                <p>
                  <a
                    id='buttonlinkaddress'
                    className='bg-transparent common-btn-transition mt-[3rem] inline-flex h-[4.4rem] items-center justify-center rounded-[0.6rem] border-[0.1rem] border-primary px-[2rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] text-primary hover:border-primary--v2 hover:text-primary--v2'
                    href={modalInfo?.buttonlinkaddress}
                  >
                    {modalInfo?.contactbutton}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default GeoPopup;
